import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalComponent = ({ children, open, toggle, className, header }) => {
	return (
		<>
			<Modal isOpen={open} toggle={toggle} centered={true}>
				{header && <ModalHeader toggle={toggle}>{header}</ModalHeader>}
				<ModalBody className={className}>{children}</ModalBody>
			</Modal>
		</>
	);
};

export default ModalComponent;
