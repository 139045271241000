import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import NavLogo from "../../asset/navlogo.png";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

const Navbar = () => {
	const buttonStyle = {
		fontSize: "0.875rem",
		"@media (max-width: 600px)": {
			fontSize: "0.8rem",
		},
	};

	const navigate = useNavigate();

	return (
		<WrapNavbar>
			<div className="d-flex justify-content-between align-items-center">
				<div className="">
					<NavLink
						className="nav_link d-flex justify-content-between align-items-center"
						to="/"
					>
						<img src={NavLogo} alt="NavLogo" />
						<div>
							<h3>Transit</h3>
							<h4>Wallet Limited</h4>
						</div>
					</NavLink>
				</div>
				<ul className="d-flex justify-content-between align-items-center">
					<li>
						<Link
							to="send-div"
							spy={true}
							smooth={true}
							className="nav-link"
							onClick={() => navigate("/")}
						>
							Send money
						</Link>
					</li>
					<li>
						<Link
							to="receive"
							spy={true}
							smooth={true}
							className="nav-link"
							onClick={() => navigate("/")}
						>
							Recieve money
						</Link>
					</li>
					<li>
						<Link
							to="how-to"
							spy={true}
							smooth={true}
							className="nav-link"
							onClick={() => navigate("/")}
						>
							How to send money
						</Link>
					</li>
					<li>
						<NavLink className="nav_link" to="/transfer">
							Track a transfer
						</NavLink>
					</li>

					<li>
						<NavLink className="nav_link" to="/about-us">
							About Us
						</NavLink>
					</li>
				</ul>
				<Stack spacing={2} direction="row">
					<Button
						sx={buttonStyle}
						className="style-button-outline"
						variant="outlined"
						onClick={() => navigate("/login")}
					>
						Login
					</Button>
					<Button
						sx={buttonStyle}
						className="style-button"
						variant="contained"
						onClick={() => navigate("/register")}
					>
						Sign Up
					</Button>
				</Stack>
			</div>
		</WrapNavbar>
	);
};

export default Navbar;

const WrapNavbar = styled.div`
	padding: 5px 70px;
	position: fixed;
	background: #ffffff;
	z-index: 10;
	width: 100%;
	.nav_link {
		text-decoration: none;
	}
	h3 {
		font-family: "Futura Md BT";
		font-style: normal;
		font-weight: 700;
		font-size: 20.112px;
		line-height: 24px;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: #5925dc;
	}
	h4 {
		font-family: "Average";
		font-style: normal;
		font-weight: 400;
		font-size: 14.5382px;
		line-height: 18px;
		color: #ee750d;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		margin: 15px 25px;
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: -0.288889px;
		color: #000000;
		position: relative;
		cursor: pointer;

		a {
			color: #000;
		}
	}
	.style-button {
		background: #0037ba;
		border-radius: 3px;
	}
	.style-button-outline {
		outline-color: #0037ba;
		color: #0037ba;
	}
	li:not(:last-child):hover::before {
		width: 100%;
	}
	@media (max-width: 1300px) {
		ul {
			display: none !important;
		}
		padding: 5px 40px;
	}
	@media (max-width: 500px) {
		padding: 5px 10px;
	}
`;
