import React, { useEffect } from "react";
import Navbar from "../component/utilities/Navbar";
import styled from "styled-components";
import Footer from "../component/Footer";

const Cookies = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<div
				style={{
					backgroundImage:
						"url(" + require("../asset/backgroundcover.png") + ")",
					height: "40vh",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					zIndex: "-1",
				}}
			>
				<WrapNavbar>
					<div className="style-value-banner">
						<div className="value-content">
							<h3>Cookie Policy</h3>
						</div>
					</div>
				</WrapNavbar>
			</div>
			<PDiv>
				<div className="container">
					<h5>Introduction:</h5>
					<p>
						Transit Wallet Limited ("we", "us", "our") uses cookies
						and similar technologies to improve the functionality
						and user experience of our website and mobile app, which
						provide international money transfer services to our
						customers. This policy outlines the types of cookies we
						use, how we use them, and your options regarding
						cookies.
					</p>
					<h5>What are cookies?</h5>
					<p>
						Cookies are small text files that are placed on your
						device when you visit a website. They are used to
						collect information about your browsing behavior and
						preferences, which can help to improve the functionality
						and user experience of the website.
					</p>
					<h5>What cookies do we use?</h5>
					<p>
						We use both session cookies and persistent cookies on
						our website and mobile app. Session cookies are
						temporary and are deleted when you close your browser.
						Persistent cookies remain on your device until they
						expire or are deleted.
					</p>
					<h5>We use the following types of cookies:</h5>
					<p>
						<b>Essential cookies:</b> These cookies are necessary
						for the proper functioning of our website and mobile
						app. They enable you to access the secure areas of our
						website, use our online services, and complete
						transactions.
					</p>
					<p>
						<b>Analytical cookies:</b> These cookies allow us to
						collect information about how visitors use our website
						and mobile app. We use this information to improve the
						functionality and user experience of our website.
					</p>
					<p>
						<b>Advertising cookies:</b> These cookies are used to
						deliver advertisements that are relevant to your
						interests. They may also be used to limit the number of
						times you see an advertisement and measure the
						effectiveness of advertising campaigns.
					</p>
					<h5>How do we use cookies?</h5>
					<p>We use cookies to:</p>
					<ul>
						<li>
							Remember your preferences and settings, such as
							language and currency preferences.
						</li>
						<li>
							Improve the functionality and user experience of our
							website and mobile app.
						</li>
						<li>
							Analyze how visitors use our website and mobile app
							to improve our services.
						</li>
						<li>
							Deliver targeted advertisements that are relevant to
							your interests.
						</li>
					</ul>
					<h5>Your options:</h5>
					<p>
						Most web browsers allow you to control cookies through
						their settings. You can configure your browser to accept
						all cookies, reject all cookies, or notify you when a
						cookie is set. However, disabling cookies may limit your
						ability to use certain features of our website and
						mobile app.
					</p>
					<h5>Changes to this policy:</h5>
					<p>
						We may update this policy from time to time to reflect
						changes in our use of cookies or changes in applicable
						laws and regulations. We encourage you to review this
						policy periodically for any updates.
					</p>
					<h5>Contact Us:</h5>
					<p>
						If you have any questions or concerns about our use of
						cookies, please contact us at{" "}
						<a href="mailto:policy@transitwall.com">
							policy@transitwall.com
						</a>
					</p>
				</div>
			</PDiv>
			<Footer />
		</div>
	);
};

export default Cookies;

const WrapNavbar = styled.div`
	width: 100%;
	padding-top: 100px;
	text-align: center;
	height: 100%;
	margin: auto;
	background: rgba(0, 0, 0, 0.67);
	.style-value-banner {
		display: "flex";
	}
	.value-content {
		padding-top: 60px;
		margin: auto;
		width: 50%;
	}
	h3 {
		font-weight: 600;
		font-size: 48px;
		line-height: 60px;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #ee750d;
	}
`;

const PDiv = styled.div`
	margin-top: 40px;
	padding-bottom: 50px;

	h5,
	p {
		margin-bottom: 10px;
	}
`;
