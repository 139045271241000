import React, { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Nigeria from "../asset/nigeria.png";
import USA from "../asset/usa.png";
import KeyIcon from "../asset/keyicon.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CurrencyInput from "react-currency-input-field";
import formatCurrency, { numberWithCommas } from "../utils/formatCurrency";
import { useNavigate } from "react-router-dom";
import { BiErrorCircle } from "react-icons/bi";
import ModalComponent from "./ModalComponent";
//

const BannerTransfer = () => {
	const navigate = useNavigate();

	const [amountSent, setAmountSent] = useState("");
	const [amountReceived, setAmountReceived] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [total, setTotal] = useState(0);
	const [fee, setFee] = useState(0);

	const transferHandler = () => {
		setOpenModal(true);
	};

	return (
		<WrappBanner>
			<WrappRight>
				<div className="right-content">
					<h3>
						Keep your business moving with super fast International
						money transfer
					</h3>
					<h4>Quick and safe funds transfer any time, any day</h4>
					<div className="d-flex justify-content-between align-items-center mt-5">
						<Button
							className="getstarted"
							variant="contained"
							endIcon={<ArrowForwardIcon />}
						>
							Get Started
						</Button>
						<div className="d-flex justify-content-start align-items-center">
							<img src={KeyIcon} alt="KeyIcon" />
							<p>
								Safe and secure <br /> transactions
							</p>
						</div>
					</div>
				</div>
			</WrappRight>
			<WrappLeft>
				<div className="banner-form">
					<div className="row banner-input">
						<div className="col-xl-6">
							<label>From</label>
							<div className="input-style">
								<div className="nav-search">
									<img
										placeholder="USA"
										src={USA}
										alt="USA"
									/>
								</div>
								<input
									type="text"
									placeholder="USD"
									className="w-100"
									disabled={true}
								/>
								<div className="flag-arrow">
									<KeyboardArrowDownIcon className="arrow-font" />
								</div>
							</div>
						</div>
						<div className="col-xl-6">
							<label>To</label>
							<div className="input-style">
								<div className="nav-search">
									<img
										placeholder="Nigeria"
										src={Nigeria}
										alt="USA"
									/>
								</div>
								<input
									type="text"
									placeholder="NGN"
									className="w-100"
									disabled={true}
								/>
								<div className="flag-arrow">
									<KeyboardArrowDownIcon className="arrow-font" />
								</div>
							</div>
						</div>
					</div>
					<label className="mt-3">Amount you send</label>
					<CurrencyInput
						id="input-example"
						name="input-name"
						placeholder=""
						decimalsLimit={2}
						onValueChange={(value, name) => {
							setAmountSent(value);
							setAmountReceived(value ? value * 700 : 0);
							setFee(4);
						}}
						className="w-100"
						value={amountSent}
						prefix="$ "
					/>
					<label className="mt-3">Receiver get</label>
					<CurrencyInput
						id="input-example"
						name="input-n"
						placeholder=""
						decimalsLimit={2}
						onValueChange={(value, name) =>
							setAmountReceived(value)
						}
						className="w-100"
						value={amountReceived}
						prefix="₦ "
						disabled={true}
					/>
					<p style={{ fontSize: "0.9rem" }}>$1 - ₦700</p>
					<div className="fee-bank">
						<div className="bank">
							<p>Select Receiver's Bank</p>
							<select>
								<option value={""}>Select Bank</option>
							</select>
						</div>
						<div className="fee mb-3">
							<span>Transfer Fee:</span>
							<span className="bold">${formatCurrency(fee)}</span>
						</div>
						<div className="fee">
							<span>Total Pay:</span>
							<span className="bold">
								₦{numberWithCommas(2800 + amountReceived)}
							</span>
						</div>
					</div>
					<button
						onClick={transferHandler}
						className="w-100 mt-4 py-2 mb-5 send-btn"
					>
						Send
					</button>
				</div>
			</WrappLeft>
			<ModalComponent
				open={openModal}
				toggle={() => setOpenModal(!openModal)}
			>
				<ModalWrap>
					<div className="text-center">
						<BiErrorCircle color="green" size={50} />
						<p className="mt-4">
							Please Login to your account to proceed
						</p>
						<button
							onClick={() => {
								setOpenModal(false);
								navigate("/login");
							}}
						>
							Login
						</button>
					</div>
				</ModalWrap>
			</ModalComponent>
		</WrappBanner>
	);
};

export default BannerTransfer;

const WrappBanner = styled.div`
	background: linear-gradient(
		90deg,
		#5925dc 0%,
		#6b2fd6 6.25%,
		#7a37cf 12.5%,
		#873ec9 18.75%,
		#9244c2 25%,
		#9d4aba 31.25%,
		#a64fb3 37.5%,
		#af54aa 43.75%,
		#b858a2 50%,
		#c05c98 56.25%,
		#c7608e 62.5%,
		#ce6483 68.75%,
		#d56876 75%,
		#dc6b68 81.25%,
		#e26f56 87.5%,
		#e8723e 93.75%,
		#ee750d 100%
	);
	display: flex;
	flex-direction: row;
	width: 100%;
	/* height: 100vh; */
	padding-top: 100px;
	@media (max-width: 870px) {
		flex-direction: column;
	}
`;

const WrappRight = styled.div`
	width: 50%;
	display: flex;

	h3 {
		font-weight: 600;
		font-size: 50px;
		line-height: 74px;
		text-transform: capitalize;
		color: #ffffff;
	}
	h4 {
		font-weight: 400;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: -0.3px;
		color: #ededed;
	}
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 1.5px;
		color: #ffffff;
	}
	.getstarted {
		background: #ee750d;
		border-radius: 3px;
		font-weight: 500;
		/* font-size: 14px; */
		line-height: 28px;
		letter-spacing: 0.175px;
		color: #fefefe;
		/* padding: 10px 30px; */
		/* margin-right: 100px; */
	}
	.right-content {
		width: 80%;
		height: 80%;
		margin: auto;
	}
	.style-button {
		background: #0037ba;
		border-radius: 3px;
	}
	@media (max-width: 1200px) {
		h3 {
			font-size: 40px;
			line-height: 54px;
		}
		width: 100%;
	}
	@media (max-width: 870px) {
		padding: 50px 0;
	}
`;

const WrappLeft = styled.div`
	width: 50%;
	padding: 30px;
	display: flex;
	label {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #616161;
	}
	.banner-form {
		background: #ffffff;
		border-radius: 10px;
		width: 80%;
		/* height: 80%; */
		margin: auto;
		padding: 40px;
	}
	.banner-input input {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
		padding-left: 60px;
	}
	.input-style {
		position: relative;
	}
	.nav-search {
		position: absolute;
		top: 10px;
		left: 13px;
	}
	.flag-arrow {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.arrow-font {
		font-size: 30px;
	}
	.send-btn {
		background: #5925dc;
		border-radius: 3px;
		font-family: "Livvic";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.175px;
		color: #fefefe;
	}
	@media (max-width: 1200px) {
		width: 100%;
		padding: 10px;
	}
	.fee-bank {
		margin-top: 10px;
		.bank {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 15px;
			margin-bottom: 15px;

			select {
				width: 200px;
			}
		}
		.fee {
			display: flex;
			justify-content: space-between;
			align-items: center;

			span.bold {
				font-size: 1.1rem;
				font-weight: 600;
			}
		}
	}
`;

const ModalWrap = styled.div`
	button {
		background: #5925dc;
		color: #fff;
		margin-top: 30px;
		padding: 8px 30px;
		border-radius: 5px;
	}
`;
