import React from "react";
import styled from "styled-components";
import OlatundePix from "../asset/olatunde.png";

const Olatunde = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-lg-6 col-12">
          <h5>Director</h5>
          <h4>Olatunde Oyeyemi</h4>
          <p>
            An accomplished, versatile, dynamic, collaborative, and
            well-qualified professional with years of experience in IT.
            Experience managing networks in the telecommunications industry. “A
            significant achievement of mine is moving the support team towards
            sensitive and competent so that networks and applications are
            managed and monitored proactively.” has Expertise in technical
            support, network deployment, intensive network analysis, cloud
            networking, ITIL framework based lifecycle management, system
            administration, and project management. Excellent communicator and
            mentor to guide and develop active partnerships with clients,
            colleagues, and other stakeholders. Achieved a great number of
            projects as team player and others working independently.
          </p>
        </div>
        <div className="col-lg-6 d-none d-lg-block">
          <img src={OlatundePix} alt="OlatundePix" />
        </div>
      </div>
    </Wrapper>
  );
};

export default Olatunde;

const Wrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    line-height: 49px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding: 10px 0 30px 0;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  text-align: justify;
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
`;

const WrappRight = styled.div`
  width: 45%;
  margin: 50px;
`;

const WrappLeft = styled.div`
  width: 55%;
  margin: 50px;
`;
