import React, { useEffect } from "react";
import Akinpelu from "../component/Akinpelu";
import BannerValue from "../component/BannerValue";
import BoardDirectors from "../component/BoardDirectors";
import Footer from "../component/Footer";
import Olabanji from "../component/Olabanji";
import Olatunde from "../component/Olatunde";
import PatricGold from "../component/PatricGold";
import StudyPatricgold from "../component/StudyPatricgold";
import TransferLink from "../component/TransferLink";
import Navbar from "../component/utilities/Navbar";
import PatrickgoldHistory from "./../component/PatrickgoldHistory";

const ValuePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<BannerValue />
			<PatrickgoldHistory />
			<StudyPatricgold />
			<BoardDirectors />
			<PatricGold />
			<Akinpelu />
			<Olatunde />
			<Olabanji />
			<TransferLink />
			<Footer />
		</div>
	);
};

export default ValuePage;
