import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CreatAccount = () => {
	return (
		<Wrapper id="receive">
			<div className="create-aacount">
				<div className="create-body">
					<h4>Get paid like a local</h4>
					<h3>
						Transfer and receive funds for business anywhere in the
						world
					</h3>
					<Button
						className="getstarted"
						variant="contained"
						endIcon={<ArrowForwardIcon />}
					>
						Create Account
					</Button>
				</div>
			</div>
		</Wrapper>
	);
};

export default CreatAccount;

const Wrapper = styled.div`
	padding: 50px;
	text-align: center;
	.create-aacount {
		background: #5925dc;
		display: flex;
		padding: 50px 0;
	}
	.create-body {
		width: 70%;
		height: 50%;
		margin: auto;

		h4 {
			font-weight: 400;
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.2px;
			text-transform: capitalize;
			color: #ee750d;
		}
		h3 {
			font-weight: 600;
			font-size: 48px;
			line-height: 60px;
			letter-spacing: 0.2px;
			text-transform: capitalize;
			color: #ffffff;
			padding: 50px;
		}
		.getstarted {
			background: #ee750d;
			border-radius: 3px;
			font-weight: 500;
			font-size: 14px;
			line-height: 28px;
			letter-spacing: 0.175px;
			color: #fefefe;
		}
		@media (max-width: 720px) {
			width: 100%;
			height: 70%;
			h3 {
				font-size: 28px;
				line-height: 40px;
			}
		}
	}
`;
