import "./App.css";

import MainPage from "./pages";

function App() {
	return (
		<div className="">
			<MainPage />
		</div>
	);
}

export default App;
