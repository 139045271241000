import React, { useEffect } from "react";
import Navbar from "../component/utilities/Navbar";
import BannerTransfer from "../component/BannerTransfer";
import Footer from "../component/Footer";

const Transfer = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<BannerTransfer />
			<Footer />
		</div>
	);
};

export default Transfer;
