import React from "react";
import styled from "styled-components";
import Iphone from "../asset/iphone.png";
import moduleName from "../asset/userfont.png";

const TransitWallet = () => {
	return (
		<Wrapper id="how-to">
			<h3>How Transit Wallet Works.</h3>
			<WrappContent>
				<WrappLeft>
					<img src={Iphone} alt="Iphone" />
				</WrappLeft>
				<WrappRight>
					<div className="box one">
						<span>
							<img src={moduleName} alt="Icon" />
						</span>
						<div>
							<h5>Create A Free Account</h5>
							<p>
								Sign up on the transit wallet website using your
								email and company details and verify your
								account within a minute
							</p>
						</div>
					</div>
					<div className="box two">
						<span>
							<img src={moduleName} alt="Icon" />
						</span>
						<div>
							<h5>Perform Transaction</h5>
							<p>
								Transfer and receive money from anywhere in the
								world to enjoy our juicy rates with no hidden
								charges
							</p>
						</div>
					</div>
					<div className="box three">
						<span>
							<img src={moduleName} alt="Icon" />
						</span>
						<div>
							<h5>Track your money</h5>
							<p>
								Monitor your transaction with our web
								application
							</p>
						</div>
					</div>
				</WrappRight>
			</WrappContent>
		</Wrapper>
	);
};

export default TransitWallet;

const Wrapper = styled.div`
	padding: 5px 50px;
	h3 {
		font-weight: 600;
		font-size: 48px;
		line-height: 60px;
		text-align: center;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #212121;
		padding: 100px 0;
	}
`;
const WrappContent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const WrappLeft = styled.div`
	width: 40%;
	text-align: center;
`;
const WrappRight = styled.div`
	width: 60%;

	.box {
		width: 400px;
		display: flex;
		align-items: center;
		margin-bottom: 35px;
		padding: 10px 30px;
		border-radius: 50px;
		font-size: 0.9rem;

		h5 {
			margin-bottom: 4px;
		}

		&.one {
			border: 2px solid #5925dc;

			span {
				background-color: #5925dc;
			}
			h5 {
				color: #5925dc;
			}
		}
		&.two {
			border: 2px solid #ee750d;
			margin-left: 20%;

			span {
				background-color: #ee750d;
			}
			h5 {
				color: #ee750d;
			}
		}
		&.three {
			border: 2px solid #02b59b;
			margin-left: 40%;

			span {
				background-color: #02b59b;
			}
			h5 {
				color: #02b59b;
			}
		}
		span {
			display: inline-flex;
			height: 50px;
			width: 50px;
			border-radius: 50%;
			padding: 5px;
			margin-right: 12px;
		}
	}
`;
