import React from "react";
import styled from "styled-components";

const BoardDirectors = () => {
  return (
    <div
      style={{
        backgroundImage: "url(" + require("../asset/servicebackgrod.png") + ")",
        height: "60vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: "-1",
      }}
      className="row p-3 w-100">
      <div className="col-xl-6 col-12 d-flex">
        <Wrapper>
          <h4>Board of Directors</h4>
          <p>
            We are led by a team of business leaders who have a wide range of
            experience leading top financial institutions
          </p>
        </Wrapper>
      </div>
    </div>
  );
};

export default BoardDirectors;

const Wrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #ee750d;
  }
  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #ffffff;
  }
  background: rgba(0, 0, 0, 0.67);
  padding: 50px;
  text-align: center;
  margin: auto;

  @media (max-width: 600px) {
    h4 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media (max-width: 400px) {
    h4 {
      line-height: 35px;
      padding-bottom: 10px;
    }
    padding: 20px;
  }
`;
