import React from "react";
import LinkedInLogo from "../asset/linkedin.png";
import TwitterLogo from "../asset/Twitter.png";
import FacebookLogo from "../asset/facebook.png";
import styled from "styled-components";
import NavLogo from "../asset/navlogo.png";
import { Link, useNavigate } from "react-router-dom";
import { Link as ALink } from "react-scroll";

const Footer = () => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<div className="row">
				<div className="col-lg-2 col-12 mb-5">
					<div className="style-image">
						<img src={NavLogo} alt="NavLogo" />
						<div>
							<h3>Transit</h3>
							<h4>Wallet Limited</h4>
						</div>
					</div>
				</div>
				<div className="col-lg-10 col-12">
					<div className="row">
						<div className="col-lg-3 col-sm-6 mb-5">
							{" "}
							<ul>
								<li>Send money</li>
								<li>
									<Link to="/transfer">
										Send money to U.S
									</Link>
								</li>
								<li>
									<Link to="/transfer">
										Send money to Canada
									</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-3 col-sm-6 mb-5">
							<ul>
								<li>Create Account</li>
								<li>
									<ALink
										to="send-div"
										spy={true}
										smooth={true}
										onClick={() => navigate("/")}
									>
										Send money
									</ALink>
								</li>
								<li>
									<Link
										to="receive"
										spy={true}
										smooth={true}
										onClick={() => navigate("/")}
									>
										Recieve money
									</Link>
								</li>
								<li>
									<Link to="/transfer">Track transfer</Link>
								</li>
								<li>
									<Link to="/about-us">About us</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-3 col-sm-6 mb-5">
							<ul>
								<li>Terms of Use</li>
								<li>
									<Link to="/privacy-policy">
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link to="/cookies">Cookie policcy</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-3 col-sm-6 mb-5">
							<ul>
								<li>Contact</li>
								<li>+1 (925) 726-6634</li>
								<li>Alwood Way Sw, Edmonton, Alberta Canada</li>
								<li>
									<img
										className=""
										src={FacebookLogo}
										alt="FacebookLogo"
									/>
									<img
										className="mx-3"
										src={TwitterLogo}
										alt="TwitterLogo"
									/>
									<img
										src={LinkedInLogo}
										alt="LinkedInLogo"
									/>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default Footer;

const Wrapper = styled.div`
	padding: 60px;
	background: #0b090e;
	/* .style-footer {
    display: flex;
  } */
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 140.49%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #494949;
		padding-top: 30px;
	}
	/* .style-image {
    padding-right: 150px;
  }
  .style-footer-list {
    display: flex;
    margin: 0 100px;
  } */
	h3 {
		font-family: "Futura Md BT";
		font-style: normal;
		font-weight: 700;
		font-size: 20.112px;
		line-height: 24px;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: #5925dc;
	}
	h4 {
		font-family: "Average";
		font-style: normal;
		font-weight: 400;
		font-size: 14.5382px;
		line-height: 18px;
		color: #ee750d;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		/* margin: 0 50px; */
		li {
			font-weight: 400;
			font-size: 16.3886px;
			line-height: 31px;
			letter-spacing: -0.295905px;
			color: #999999;

			a {
				color: #999999;
				text-decoration: none;
				cursor: pointer;
			}
		}
		li:first-child {
			font-weight: 500;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.741083px;
			text-transform: uppercase;
			color: #ffffff;
		}
	}
	@media (max-width: 400px) {
		padding: 20px;
	}
`;
