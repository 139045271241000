import React from "react";
import NavLogo from "../../asset/navlogo.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const AuthBg = ({ children }) => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<div
				className="left"
				style={{
					backgroundImage: "url('/auth-bg.png')",
				}}
			></div>
			<div className="right">
				<div className="logo" onClick={() => navigate("/")}>
					<img src={NavLogo} alt="NavLogo" />
					<div>
						<h3>Transit</h3>
						<h4>Wallet Limited</h4>
					</div>
				</div>
				{children}
			</div>
		</Wrapper>
	);
};

export default AuthBg;

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	display: flex;

	.left {
		width: 50%;
		height: 100vh;
		background-size: cover;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.4);
		}
	}
	.right {
		background: #fff;
		width: 50%;
		padding: 50px 70px;

		.logo {
			display: flex;
			align-items: center;
			cursor: pointer;
			h3 {
				font-family: "Futura Md BT";
				font-style: normal;
				font-weight: 700;
				font-size: 20.112px;
				line-height: 24px;
				letter-spacing: 0.06em;
				text-transform: uppercase;
				color: #5925dc;
			}
			h4 {
				font-family: "Average";
				font-style: normal;
				font-weight: 400;
				font-size: 14.5382px;
				line-height: 18px;
				color: #ee750d;
			}
		}
	}
`;
