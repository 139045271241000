import React, { useState } from "react";
import AuthBg from "../component/utilities/AuthBg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiMail, FiPhone, FiUser } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import ModalComponent from "../component/ModalComponent";

const Register = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");
	const [check, setCheck] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [load, setLoad] = useState(false);

	const registerHandler = () => {
		if (email && name && mobile) {
			if (check) {
				setLoad(true);

				setTimeout(() => {
					setLoad(false);
					setOpenModal(true);
				}, 2000);
			} else {
				alert("Please accept our terms and condition to proceed.");
			}
		} else {
			alert("All fields are required.");
		}
	};

	return (
		<AuthBg>
			<Wrapper>
				<div className="head">
					<div>
						<h5>Create a free account</h5>
						<p>Let's get you started in 30 seconds</p>
					</div>
					<Link to="/login">Login</Link>
				</div>
				<div className="form">
					<div className="pos">
						<FiUser name="user" />
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Full Name"
							disabled={load}
						/>
					</div>
					<div className="pos">
						<FiMail name="user" />
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Work Email"
							disabled={load}
						/>
					</div>
					<div className="pos">
						<FiPhone name="user" />
						<input
							type="tel"
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
							placeholder="Phone Number"
							disabled={load}
						/>
					</div>
					<div className="check">
						<input
							type="checkbox"
							onChange={(e) => setCheck(e.target.checked)}
							value={check}
							disabled={load}
						/>
						<span>
							By creating an account, you agree with{" "}
							<a href="/privacy-policy" target="_blank">
								Terms and Conditions & Privacy Policy
							</a>
						</span>
					</div>
					<button disabled={load} onClick={registerHandler}>
						{load ? "Please Wait..." : "Next"}
					</button>
				</div>
			</Wrapper>
			<ModalComponent
				open={openModal}
				toggle={() => setOpenModal(!openModal)}
			>
				<ModalWrap>
					<div className="text-center">
						<BiErrorCircle color="red" size={50} />
						<p className="mt-4">
							We are unable to send OTP to verify your details at
							this time. Please try again later.
						</p>
						<button onClick={() => setOpenModal(false)}>
							Okay
						</button>
					</div>
				</ModalWrap>
			</ModalComponent>
		</AuthBg>
	);
};

export default Register;

const Wrapper = styled.div`
	margin-top: 20px;
	.head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			color: #5925dc;
			font-weight: 600;
			font-size: 1.1rem;
		}
	}
	.form {
		margin-top: 50px;
		a {
			color: #5925dc;
		}
		.pos {
			position: relative;
			margin-bottom: 20px;

			input {
				background: #fff;
				height: 50px;
				width: 100%;
				display: block;
				border: 1px solid #767676;
				padding-left: 50px;
			}
			svg {
				position: absolute;
				top: 15px;
				left: 20px;
			}
		}
		button {
			width: 100%;
			display: block;
			background: #5925dc;
			padding: 15px 0;
			margin-top: 15px;
			color: #fff;
			text-transform: uppercase;
		}
		.check {
			span {
				margin-left: 10px;
			}
		}
	}
`;

const ModalWrap = styled.div`
	button {
		background: #5925dc;
		color: #fff;
		margin-top: 30px;
		padding: 8px 30px;
		border-radius: 5px;
	}
`;
