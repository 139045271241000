import React from "react";

import styled from "styled-components";
import Target from "../asset/target.png";
import Vision from "../asset/vision.png";

const StudyPatricgold = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-lg-6">
          <div>
            <img
              className="w-100 d-none d-lg-block"
              src={Target}
              alt="Target"
            />
            <h4>Our Mission</h4>
            <p>
              Our mission is strictly to build payment infrastructures through
              our seamless cutting-edge technology, our partnerships are majorly
              centred on highly regulated International Money transfer companies
              helping them to seamlessly drive business transactional flow
              including cultured business entities.
            </p>
          </div>
        </div>
        <div className="col-lg-6 ">
          <div>
            <img
              className="w-100 d-none d-lg-block"
              src={Vision}
              alt="Vision"
            />
            <h4>Our Vision</h4>
            <p>
              As a regulated entity our vision is to create an easy flow amongst
              the remittance industries for regulated entities and business
              entities so to make and accept any payment, anywhere from across
              Africa and around the world.
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default StudyPatricgold;

const Wrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    line-height: 49px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding: 30px 0;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  text-align: justify;
  @media (max-width: 500px) {
    h4 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
`;
