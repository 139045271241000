import React from "react";
import styled from "styled-components";
import YoungGirl from "../asset/younggirl.png";
import EarthMoney from "../asset/money-around-world.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Service = () => {
	return (
		<Wrapper id="send-div">
			<div className="row w-100">
				<div className="col-xl-6 service-content ">
					<h4>SUPERFAST TRANSACTIONS</h4>
					<h3>Transfer and recieve funds from abroad</h3>
					<p>
						Manage your international transactions and transfer
						funds 24/7 without hassle. pay and get paid with one
						click
					</p>
					<h4>
						Get Started
						<span>
							<KeyboardArrowRightIcon />
						</span>
					</h4>
				</div>
				<div className="col-xl-6 d-none d-xl-block">
					<img className="w-100" src={EarthMoney} alt="EarthMoney" />
				</div>
			</div>
		</Wrapper>
	);
};

export default Service;

const Wrapper = styled.div`
	width: 100%;

	h4 {
		font-weight: 400;
		font-size: 20px;
		line-height: 25px;
		align-items: center;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #5925dc;
	}
	h3 {
		font-weight: 600;
		font-size: 48px;
		line-height: 60px;
		align-items: center;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #212121;
		padding: 30px 0;
	}
	p {
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;
		align-items: center;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #757575;
		padding-bottom: 30px;
	}
	.service-content {
		padding: 70px;
		@media (max-width: 1200px) {
			width: 70%;
		}
		@media (max-width: 720px) {
			width: 100%;
		}
	}
`;
