import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Nigeria from "../asset/nigeria.png";
import USA from "../asset/usa.png";
import KeyIcon from "../asset/keyicon.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
//

const Banner = () => {
	const navigate = useNavigate();

	return (
		<WrappBanner>
			<WrappRight>
				<div className="right-content">
					<h3>
						Keep your business moving with super fast International
						money transfer
					</h3>
					<h4>Quick and safe funds transfer any time, any day</h4>
					<div className="d-flex justify-content-between align-items-center mt-5">
						<Button
							className="getstarted"
							variant="contained"
							endIcon={<ArrowForwardIcon />}
							onClick={() => navigate("/login")}
						>
							Get Started
						</Button>
						<div className="d-flex justify-content-start align-items-center">
							<img src={KeyIcon} alt="KeyIcon" />
							<p>
								Safe and secure <br /> transactions
							</p>
						</div>
					</div>
				</div>
			</WrappRight>
			<WrappLeft>
				<div className="banner-form">
					<div className="row banner-input">
						<div className="col-xl-6">
							<label>From</label>
							<div className="input-style">
								<div className="nav-search">
									<img
										placeholder="USA"
										src={USA}
										alt="USA"
									/>
								</div>
								<input
									type="text"
									placeholder="USD"
									className="w-100"
								/>
								<div className="flag-arrow">
									<KeyboardArrowDownIcon className="arrow-font" />
								</div>
							</div>
						</div>
						<div className="col-xl-6">
							<label>To</label>
							<div className="input-style">
								<div className="nav-search">
									<img
										placeholder="Nigeria"
										src={Nigeria}
										alt="USA"
									/>
								</div>
								<input
									type="text"
									placeholder="NGN"
									className="w-100"
								/>
								<div className="flag-arrow">
									<KeyboardArrowDownIcon className="arrow-font" />
								</div>
							</div>
						</div>
					</div>
					<label className="mt-3">Amount you send</label>
					<input
						type="text"
						placeholder="$3,542.00"
						className="w-100"
					/>
					<label className="mt-3">Receiver get</label>
					<input
						type="text"
						placeholder="₦20,598.65"
						className="w-100"
					/>
					<button
						className="w-100 mt-4 py-2 mb-5 send-btn"
						onClick={() => navigate("/login")}
					>
						Send
					</button>
				</div>
			</WrappLeft>
		</WrappBanner>
	);
};

export default Banner;

const WrappBanner = styled.div`
	background: linear-gradient(
		90deg,
		#5925dc 0%,
		#6b2fd6 6.25%,
		#7a37cf 12.5%,
		#873ec9 18.75%,
		#9244c2 25%,
		#9d4aba 31.25%,
		#a64fb3 37.5%,
		#af54aa 43.75%,
		#b858a2 50%,
		#c05c98 56.25%,
		#c7608e 62.5%,
		#ce6483 68.75%,
		#d56876 75%,
		#dc6b68 81.25%,
		#e26f56 87.5%,
		#e8723e 93.75%,
		#ee750d 100%
	);
	display: flex;
	flex-direction: row;
	width: 100%;
	/* height: 100vh; */
	padding-top: 100px;
	@media (max-width: 870px) {
		flex-direction: column;
	}
`;

const WrappRight = styled.div`
	width: 50%;
	display: flex;

	h3 {
		font-weight: 600;
		font-size: 50px;
		line-height: 74px;
		text-transform: capitalize;
		color: #ffffff;
	}
	h4 {
		font-weight: 400;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: -0.3px;
		color: #ededed;
	}
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 1.5px;
		color: #ffffff;
	}
	.getstarted {
		background: #ee750d;
		border-radius: 3px;
		font-weight: 500;
		/* font-size: 14px; */
		line-height: 28px;
		letter-spacing: 0.175px;
		color: #fefefe;
		/* padding: 10px 30px; */
		/* margin-right: 100px; */
	}
	.right-content {
		width: 80%;
		height: 80%;
		margin: auto;
	}
	.style-button {
		background: #0037ba;
		border-radius: 3px;
	}
	@media (max-width: 1200px) {
		h3 {
			font-size: 40px;
			line-height: 54px;
		}
		width: 100%;
	}
	@media (max-width: 870px) {
		padding: 50px 0;
	}
`;

const WrappLeft = styled.div`
	width: 50%;
	padding: 30px;
	display: flex;
	label {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #616161;
	}
	.banner-form {
		background: #ffffff;
		border-radius: 10px;
		width: 80%;
		/* height: 80%; */
		margin: auto;
		padding: 40px;
	}
	.banner-input input {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
		padding-left: 60px;
	}
	.input-style {
		position: relative;
	}
	.nav-search {
		position: absolute;
		top: 10px;
		left: 13px;
	}
	.flag-arrow {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.arrow-font {
		font-size: 30px;
	}
	.send-btn {
		background: #5925dc;
		border-radius: 3px;
		font-family: "Livvic";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.175px;
		color: #fefefe;
	}
	@media (max-width: 1200px) {
		width: 100%;
		padding: 10px;
	}
`;
