import React from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const TransferLink = () => {
	const navigate = useNavigate();
	return (
		<Wrapper onClick={() => navigate("/transfer")}>
			<p>
				Go back to make transfer{" "}
				<span>
					<ArrowForwardIosIcon />
				</span>
			</p>
		</Wrapper>
	);
};

export default TransferLink;

const Wrapper = styled.div`
	padding-bottom: 100px;
	padding-top: 50px;
	cursor: pointer;
	p {
		font-weight: 400;
		font-size: 18px;
		line-height: 35px;
		text-align: center;
		letter-spacing: 0.7px;
		text-transform: capitalize;
		color: #5925dc;
		/* border-bottom: 2px solid #5925dc; */

		span {
			padding-left: 10px;
		}
	}
`;
