import Navbar from "../component/utilities/Navbar";
import Banner from "../component/Banner";
import BusinessNeeds from "../component/BusinessNeeds";
import Service from "../component/Service";
import ServiceRate from "../component/ServiceRate";
import CreatAccount from "../component/CreatAccount";
import TransitWallet from "../component/TransitWallet";
import Testimonials from "../component/Testimonials";
import Footer from "../component/Footer";
import { useEffect } from "react";

const HomePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<Banner />
			<BusinessNeeds />
			<Service />
			<ServiceRate />
			<CreatAccount />
			<TransitWallet />
			<Testimonials />
			<Footer />
		</div>
	);
};

export default HomePage;
