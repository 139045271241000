import React from "react";
import styled from "styled-components";
import PatricGoldPix from "../asset/patrickgold.png";

const PatricGold = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-lg-6 col-12">
          <h5>Director</h5>
          <h4>Bamiduro Olu'tosin</h4>
          <p>
            a former banker and Vice President of West African Association Of
            Money Transmitters, Chairman of PatrickGold MicroFinance Bank and
            DurosGroup Conglomerate. Brought in Exchange4free to Nigeria and
            served as a country Director and for over 8years was a country
            partner to Smallworld Financial Services Ltd that grew from 100,000$
            to over 400 million $ sales annually. He is a global business leader
            with a career in Information Technology, Remittances, Transactional
            Banking, Mobile Applications, Mobile Banking, Financial Analysis,
            Web Analytics and Systems Analysis spanning over 20 years.He is also
            a member of Financial Reporting Council of Nigeria
            (FRC/2020/003/00000021910)
          </p>
        </div>
        <div className="col-lg-6 d-none d-lg-block">
          <img className=" " src={PatricGoldPix} alt="PatricGoldPix" />
        </div>
      </div>
    </Wrapper>
  );
};

export default PatricGold;

const Wrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    line-height: 49px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding: 10px 0 30px 0;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  text-align: justify;
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
`;

const WrappRight = styled.div`
  width: 45%;
  margin: 50px;
  /* border: 2px red solid; */
`;

const WrappLeft = styled.div`
  width: 55%;
  margin: 50px;
  @media (max-width: 500px) {
    h4 {
      line-height: 35px;
      padding-bottom: 10px;
    }
    padding: 20px;
  }
`;
