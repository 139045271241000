import React, { useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import HomePage from "./HomePage";
import ValuePage from "./ValuePage";
import Register from "./Register";
import Login from "./Login";
import Transfer from "./Transfer";
import Privacy from "./Privacy";
import Cookies from "./Cookies";

function MainPage() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <HomePage />,
		},
		{
			path: "/about-us",
			element: <ValuePage />,
		},
		{
			path: "/register",
			element: <Register />,
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/transfer",
			element: <Transfer />,
		},
		{
			path: "/privacy-policy",
			element: <Privacy />,
		},
		{
			path: "/cookies",
			element: <Cookies />,
		},
		{
			path: "*",
			element: <p>Error 404</p>,
		},
	]);

	return (
		<div className="">
			<RouterProvider router={router} />
		</div>
	);
}

export default MainPage;
