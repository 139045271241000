import React from "react";
import styled from "styled-components";
import StudyHistory from "../asset/study.png";

const PatrickgoldHistory = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-lg-6 d-none d-lg-block">
          <img className="w-100 h-100" src={StudyHistory} alt="StudyHistory" />
        </div>
        <div className="col-lg-6 col-12 px-4">
          <h4>Our History</h4>
          <p>
            Transit Wallet House Limited, founded in 2022 by a team of FinTech
            experts, is dedicated to ensuring secure and convenient money
            transfers. Our focus is primarily on B2B and C2B transactions, as
            compliance and proper identification are crucial. Utilizing our
            extensive experience in the remittance industry, we have come
            together to address the challenges faced by businesses in making and
            receiving payments across Africa. Our award-winning platform
            utilizes intelligent and reliable payment technology to provide
            businesses with a seamless and robust technology endpoint.
          </p>
        </div>
      </div>
      {/* <WrappLeft>
        
      </WrappLeft>
      <WrappRight>
        <div className="history-content">
          
        </div>
      </WrappRight> */}
    </Wrapper>
  );
};

export default PatrickgoldHistory;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  @media (max-width: 500px) {
    h4 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
`;

const WrappRight = styled.div`
  width: 50%;
  display: flex;
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    line-height: 49px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  .history-content {
    width: 100%;
    height: 90%;
    margin: auto;
  }
`;

const WrappLeft = styled.div`
  width: 50%;
  display: flex;
`;
