const formatCurrency = (value) => {
	let number = value || 0;
	return Number(number)
		.toFixed(2)
		.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default formatCurrency;
