import React from "react";
import styled from "styled-components";
import ArrowTri from "../asset/arrowtri.png";
import Sprial from "../asset/spiral.png";
import Database from "../asset/database.png";
import Graph from "../asset/graph.png";
import Horrixo from "../asset/horizontaldrop.png";
import Pserson from "../asset/person.png";

const BusinessNeeds = () => {
  return (
    <Wrapper>
      <h3>
        Whatever your business needs are, we’ve <br /> got something huge for
        you
      </h3>
      <div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={Sprial} alt="Sprial" />
              <h4>track your money</h4>
              <p>
                Know when your money is on the move and when it gets to its
                destination.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={ArrowTri} alt="ArrowTri" />
              <h4>Anti-Fraud</h4>
              <p>
                Don’t fall victim. Verify all transactions. keep you money
                protected all the time
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={Horrixo} alt="Horrixo" />
              <h4>Seamless and Stress Free</h4>
              <p>
                A seamless and stress-free international money transfer
                experience is our offer ALWAYS
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={Database} alt="Database" />
              <h4>Data protection</h4>
              <p>
                we employ cutting-edge technology to ensure your data is
                protected.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={Graph} alt="Graph" />
              <h4>Check live rates</h4>
              <p>
                keep up with everything currency and rates all over the world
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="bux-list">
              <img src={Pserson} alt="Pserson" />
              <h4>Create your account</h4>
              <p>Follow simple steps to create your own account today</p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center"></div>
      </div>
    </Wrapper>
  );
};

export default BusinessNeeds;

const Wrapper = styled.div`
  padding: 50px 70px;
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #1a1a1a;
    padding-bottom: 50px;
  }
  .bux-list {
    /* width: 100%; */
    margin: 20px;
  }
  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    padding: 20px 0;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #212121;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #616161;
  }
  @media (max-width: 1300px) {
    h3 {
      font-size: 38px;
    }
    padding: 50px 100px;
  }
  @media (max-width: 990px) {
    h3 {
      font-size: 28px;
      line-height: 40px;
    }
    padding: 50px 70px;
  }
`;
