import React from "react";
import styled from "styled-components";

const BannerValue = () => {
  return (
    <div
      style={{
        backgroundImage: "url(" + require("../asset/backgroundcover.png") + ")",
        height: "80vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: "-1",
      }}>
      <WrapNavbar>
        <div className="style-value-banner">
          <div className="value-content">
            <h3>Our Core Values</h3>
            <h4>
              World Class Service, Integrity, Growth Through Learning, Passion
              In Work and Play, Innovation and Creativity.
            </h4>
          </div>
        </div>
      </WrapNavbar>
    </div>
  );
};

export default BannerValue;

const WrapNavbar = styled.div`
  width: 100%;
  padding-top: 100px;
  text-align: center;
  height: 100%;
  margin: auto;
  background: rgba(0, 0, 0, 0.67);
  .style-value-banner {
    display: "flex";
  }
  .value-content {
    padding-top: 100px;
    margin: auto;
    width: 50%;
  }
  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #ee750d;
  }
  h4 {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #ffffff;
  }
  @media (max-width: 720px) {
    .value-content {
      width: 80%;
    }
  }
  @media (max-width: 500px) {
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 18px;
      line-height: 25px;
    }
    .value-content {
      width: 90%;
    }
  }
`;
