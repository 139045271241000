import React from "react";
import styled from "styled-components";
import AkinpeluPx from "../asset/akinpelu.png";

const Akinpelu = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-lg-6 d-none d-lg-block">
          <img src={AkinpeluPx} alt="AkinpeluPx" />
        </div>
        <div className="col-lg-6 col-12">
          <h5>Director</h5>
          <h4>Akinpelu Akinlabi</h4>
          <p>
            serial entrepreneur, Chief Executive Officer Darasan Multi Concept
            Limited and financial expert. an experienced banker with career
            spanning, seventeen years in Treasury, Liability, Generation and
            Foreign Exchange Management. Also a director at Manasseh Investments
            Ltd including Genesis Bureau de Change, Maria Global Ventures and
            Tambov enterprises. Engage in Foreign Exchange services, Financial
            investments, Importation and General merchandise also a member of
            Financial Reporting Council of Nigeria (FRC/2020/003/00000022131)
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Akinpelu;

const Wrapper = styled.div`
  h4 {
    font-weight: 600;
    font-size: 44.8px;
    line-height: 49px;
    letter-spacing: 0.98px;
    text-transform: capitalize;
    color: #5925dc;
    padding: 10px 0 30px 0;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px;
  text-align: justify;
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
`;

const WrappRight = styled.div`
  width: 45%;
  margin: 50px;
`;

const WrappLeft = styled.div`
  width: 55%;
  margin: 50px;
`;
