import React from "react";
import styled from "styled-components";
import ArrowTri from "../asset/arrowtri.png";
import Sprial from "../asset/spiral.png";
import Database from "../asset/database.png";
import Graph from "../asset/graph.png";
import Horrixo from "../asset/horizontaldrop.png";
import Pserson from "../asset/person.png";

const Testimonials = () => {
  return (
    <Wrapper>
      <p className="text-center">Testimonials</p>
      <h3>Our Happy Client</h3>
      <div className="row">
        <div className="col-lg-4 mb-3">
          <div className="bux-list">
            <p className="pb-5">
              Transit wallet works like a dream. Not only are the transactions
              fast, I like the fact that funding my business has become easier
              since I don’t have to wait for long periods of time to receive
              funds from overseas as well. Glad I found this platform
            </p>
            <p>Emmanuel Ebuka</p>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="bux-list">
            <p className="pb-5">
              I have used different fund transfer platforms before now and I can
              say I have found a new favorite. The rates are fantastic for my
              business too and I get to save a bit when I do money transfer.
            </p>
            <p>Olabamiji David</p>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="bux-list">
            <p className="pb-5">
              The service here is awesome. Not needing to enter a physical bank
              before I get the best services is really a great benefit. I have
              recommended the platform to my business associates and they only
              have nice things to say about their experience too.
            </p>
            <p>Arthur Orduh</p>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between ">
          
          
          
        </div> */}
      </div>
    </Wrapper>
  );
};

export default Testimonials;

const Wrapper = styled.div`
  padding: 70px;

  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #1a1a1a;
    padding-bottom: 50px;
    text-align: center;
  }

  .bux-list {
    width: 100%;
    padding: 20px;
    margin: 0;
    p:last-child {
      color: #5925dc;
    }
    border: 2px solid #d2d2d2;
    border-radius: 15px;
  }
  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    padding: 20px 0;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #212121;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #616161;
  }
  @media (max-width: 400px) {
    padding: 40px;
  }
`;
