import React, { useState } from "react";
import AuthBg from "../component/utilities/AuthBg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FiLock, FiMail } from "react-icons/fi";
import ModalComponent from "../component/ModalComponent";
import { BiErrorCircle } from "react-icons/bi";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [load, setLoad] = useState(false);

	const loginHandler = () => {
		if (email && password) {
			setLoad(true);

			setTimeout(() => {
				setLoad(false);
				setOpenModal(true);
			}, 2000);
		} else {
			alert("Both fields are required.");
		}
	};

	return (
		<AuthBg>
			<Wrapper>
				<div className="head">
					<div>
						<h5>Welcome back! Please Login</h5>
					</div>
					<Link to="/register">Create Account</Link>
				</div>
				<div className="form">
					<div className="pos">
						<FiMail name="user" />
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Work Email"
							disabled={load}
						/>
					</div>
					<div className="pos">
						<FiLock name="user" />
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							disabled={load}
						/>
					</div>
					<button disabled={load} onClick={loginHandler}>
						{load ? "Please wait..." : "Login"}
					</button>
				</div>
			</Wrapper>
			<ModalComponent
				open={openModal}
				toggle={() => setOpenModal(!openModal)}
			>
				<ModalWrap>
					<div className="text-center">
						<BiErrorCircle color="red" size={50} />
						<p className="mt-4">Invalid Credentials Provided.</p>
						<button onClick={() => setOpenModal(false)}>
							Okay
						</button>
					</div>
				</ModalWrap>
			</ModalComponent>
		</AuthBg>
	);
};

export default Login;

const Wrapper = styled.div`
	margin-top: 20px;
	.head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			color: #5925dc;
			font-weight: 600;
			font-size: 1.1rem;
		}
	}
	.form {
		margin-top: 50px;
		.pos {
			position: relative;
			margin-bottom: 20px;

			input {
				background: #fff;
				height: 50px;
				width: 100%;
				display: block;
				border: 1px solid #767676;
				padding-left: 50px;
			}
			svg {
				position: absolute;
				top: 15px;
				left: 20px;
			}
		}
		button {
			width: 100%;
			display: block;
			background: #5925dc;
			padding: 15px 0;
			margin-top: 15px;
			color: #fff;
			text-transform: uppercase;
		}
	}
`;

const ModalWrap = styled.div`
	button {
		background: #5925dc;
		color: #fff;
		margin-top: 30px;
		padding: 8px 30px;
		border-radius: 5px;
	}
`;
