import React, { useEffect } from "react";
import Navbar from "../component/utilities/Navbar";
import styled from "styled-components";
import Footer from "../component/Footer";

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<div
				style={{
					backgroundImage:
						"url(" + require("../asset/backgroundcover.png") + ")",
					height: "40vh",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					zIndex: "-1",
				}}
			>
				<WrapNavbar>
					<div className="style-value-banner">
						<div className="value-content">
							<h3>Our Privacy Policy</h3>
						</div>
					</div>
				</WrapNavbar>
			</div>
			<PDiv>
				<div className="container">
					<p>
						Transit Wallet Limited is committed to providing safe
						and secure international money transfer services to our
						customers. Our policies are designed to comply with
						relevant laws and regulations, protect our customers,
						and prevent money laundering and other illegal
						activities. This policy outlines the guidelines and
						procedures that govern our international money transfer
						services.
					</p>
					<h5>Scope:</h5>
					<p>
						This policy applies to all international money transfers
						offered by Transit Wallet Limited, whether through our
						website, mobile app, or in-person at our authorized
						agents.
					</p>
					<h5>Customer Due Diligence:</h5>
					<p>
						Transit Wallet Limited is committed to customer due
						diligence to prevent money laundering and other illegal
						activities. We require all customers to provide valid
						identification documents and other information as may be
						necessary to complete an international money transfer
						transaction. We reserve the right to reject any
						transaction that fails to meet our due diligence
						standards.
					</p>
					<h5>Transaction Limits:</h5>
					<p>
						Transit Wallet Limited reserves the right to set
						transaction limits for international money transfers. We
						may set different limits for different countries and
						currencies, and may adjust these limits from time to
						time based on risk and other factors. We will inform our
						customers of any transaction limits that apply to their
						specific transactions.
					</p>
					<h5>Fees and Exchange Rates:</h5>
					<p>
						Transit Wallet Limited may charge fees for international
						money transfers. The fees may vary depending on the
						country, currency, and amount of the transfer. We will
						inform our customers of the applicable fees before they
						confirm their transaction. We will also provide our
						customers with the exchange rate that will apply to
						their transfer.
					</p>
					<h5>Cancellation and Refunds:</h5>
					<p>
						Once a customer initiates an international money
						transfer, it cannot be canceled or reversed, except in
						exceptional circumstances. If a customer wishes to
						cancel a transaction, they must contact Transit Wallet
						Limited customer support immediately. Refunds may be
						granted at our discretion, and may be subject to fees
						and exchange rate fluctuations.
					</p>
					<h5>Compliance:</h5>
					<p>
						Transit Wallet Limited is committed to complying with
						all relevant laws and regulations governing
						international money transfers. We may conduct additional
						due diligence or refuse to process a transaction if we
						suspect that it may be in violation of these laws and
						regulations.
					</p>
					<h5>Conclusion:</h5>
					<p>
						Transit Wallet Limited is committed to providing safe
						and secure international money transfer services to our
						customers. Our policies are designed to comply with
						relevant laws and regulations, protect our customers,
						and prevent money laundering and other illegal
						activities. By using our services, customers agree to
						abide by these policies and guidelines.
					</p>
				</div>
			</PDiv>
			<Footer />
		</div>
	);
};

export default Privacy;

const WrapNavbar = styled.div`
	width: 100%;
	padding-top: 100px;
	text-align: center;
	height: 100%;
	margin: auto;
	background: rgba(0, 0, 0, 0.67);
	.style-value-banner {
		display: "flex";
	}
	.value-content {
		padding-top: 60px;
		margin: auto;
		width: 50%;
	}
	h3 {
		font-weight: 600;
		font-size: 48px;
		line-height: 60px;
		letter-spacing: 0.2px;
		text-transform: capitalize;
		color: #ee750d;
	}
`;

const PDiv = styled.div`
	margin-top: 40px;
	padding-bottom: 50px;

	h5,
	p {
		margin-bottom: 10px;
	}
`;
